.css-126euq6-MuiButtonBase-root-MuiListItemButton-root{
    padding-left: 12px !important;
}
.logo-style{
    font-family: 'Courgette', cursive;
}
html{
    font-size: "62.5%";
}

h2 {
    font-size: 20px;
    margin: 0;
}

h3 {
    font-size: 16px;
    margin: 0;
}
h4 {
    font-size: 14px;
    margin: 0;
}
h5 {
    font-size: 12px;
    margin: 0;
}
h6 {
    font-size: 10px;
    margin: 0;
}
.pagination .ant-pagination-item-active a{
    color: #5e35b1 !important;
}
.pagination .ant-pagination-item-active{
    border-color: #5e35b1 ;
}
.pagination .ant-pagination-item-active:hover{
    border-color: #5e35b1 ;
}
.ant-input-affix-wrapper-focused{
    border-color: #5e35b1 !important;
}
.ant-input-affix-wrapper:hover{
    border-color: #5e35b1 ;
}
.css-dev-only-do-not-override-1me4733:focus{
    border-color: #5e35b1;
}
.css-dev-only-do-not-override-1me4733:hover{
    border-color: #5e35b1 !important;
}
.ant-input{
    background-color: white;
}
.ant-input-affix-wrapper{
    background-color: white;
}
.ant-select-selector{
    background-color: white !important;
}
/* .ant-form-item-control-input-content{
    background-color: #5e35b1;
} */

.ant-form-item label{
    font-weight: bold;
    font-size: 14px !important
}

.table-row-light-violet {
    background-color: #ede7f6;
    font-weight: bold;
    font-style: italic;
}
.table-row-white {
    background-color: #ffffff;
}